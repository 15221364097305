//repertorie les scenes
export const CST = {
    SCENES : {
        PRELOAD : "PRELOAD",
        LOAD : "LOAD",
        ACCUEIL : "ACCUEIL",
        MENU : "MENU",
        CHOIXMODEJEU : "CHOIXMODEJEU",
        CHOIXTYPEPERSONNAGE : "CHOIXTYPEPERSONNAGE",
        CHOIXPERSONNAGE : "CHOIXPERSONNAGE",
        QUESTIONS : "QUESTIONS",
        JEU : "JEU",
        CALENDRIER : "CALENDRIER",
        CHOIX : "CHOIX",
        OPTIONS : "OPTIONS"
    }
}