//charge tout les assets avant de passer à la vue menu
import { CST } from "../CST"

export class loadScene extends Phaser.Scene {
    route
    prochaineScene
    liste

    constructor() {
        super({
            key: CST.SCENES.LOAD
        })
    }
    
    init(data) {
        this.route = data["route"]
        this.prochaineScene = data["scene"]
    }
    
    preload() {
        var self = this

        var progressBar = this.add.graphics();
        var progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(this.game.renderer.width / 2 - 160, this.game.renderer.height / 2 - 25, 320, 50);

        if(this.route != ""){
            this.load.rexAwait((successCallback, failureCallback) => {
                fetch('https://gestion.avoscomptes.org/api/'+this.route, {
                    method: 'GET'
                }).then((response) => {
                    return response.json()
                }).then((data)=>{
                    this.liste = data
                    
                    successCallback();
                });
            });
        }

        this.load.on('progress', function (value) {
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(self.game.renderer.width / 2 - 160 + 10, self.game.renderer.height / 2 - 25 + 10, 300 * value, 30);
        });
                    
        this.load.on('complete', function () {
            progressBar.destroy();
            progressBox.destroy();
        });

    }
    //METHODE create() EST OBLIGATOIRE DANS TOUTES LES SCENES
    //crée l'objet game
    create() {
        this.scene.start(this.prochaineScene, this.liste)
    }

}