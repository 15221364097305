import { CST } from "../CST"
export class questionsScene extends Phaser.Scene {
    questions = [];
    indexQuestion = 0;
    questionObjet;
    unlock = false;
    boutonsAnimes = [];
    data = [];
    scaleBtn = 0;
    increment = 0.1;

    constructor() {
        super({
            key: CST.SCENES.QUESTIONS
        })
    }
    init(data) {
        self = this;
        this.load.plugin('rexmodalplugin', './rexmodalplugin.min.js');

        this.load.scenePlugin({
            key: 'rexuiplugin',
            url: './rexuiplugin.min.js',
            sceneKey: 'rexUI'
        });
        this.questions = [];
        this.indexQuestion = 0;
        this.unlock = false;

        if (data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].nom_gestion == "titre_fin") {
                    this.titre = data[i].texte;
                } else if(data[i].nom_gestion == "contenu_fin") {
                    this.contenu = data[i].texte;
                }
            }
        }

        fetch('https://gestion.avoscomptes.org/api/questions', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((_questions) => {
            let questions = [];
            let estQuestionDebut = self.registry.get('questionsDebut') == undefined ? true : self.registry.get('questionsDebut'); //boolean

            _questions.forEach((question) => {
                if (question.fin != estQuestionDebut) {
                    questions.push(question);
                }
            });

            this.questions = questions;
            if (estQuestionDebut) {
                self.afficherQuestion()
            }

            self.unlock = true;
        });
    }
    create() {
        self = this

        let estQuestionDebut = this.registry.get('questionsDebut') == undefined ? true : this.registry.get('questionsDebut');

        //charge l'image de fond
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setInteractive()
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)
            .on('pointerup', function () {
                //
            })

        let textTitre = this.add.text(0, 0, estQuestionDebut ? "Pistes de réflexion" : this.titre, { fontFamily: 'Raleway-ExtraBold', fontSize: 60, color: '#8C2727' }).setDepth(2)
        textTitre.setPosition((this.game.renderer.width * 0.5) - (textTitre.displayWidth / 2), this.game.renderer.height * 0.1)
        let backgroundTitre = this.add.sprite(textTitre.x + textTitre.displayWidth / 2, textTitre.y + textTitre.displayHeight / 2, "cadre_titre").setDepth(1).setDisplaySize(textTitre.displayWidth + 50, textTitre.displayHeight + 50, 0xD4D4D4)
        this.textTitre = textTitre;
        this.backgroundTitre = backgroundTitre;
        //ajoute une boite ou mettre les infos du personnage selectionné
        //TODO : taille dynamiques
        //n'utilise pas de parametre, affichage non dynamique pour le moment
        let blocInfos = this.add.sprite(this.game.renderer.width * 0.08, this.game.renderer.height * 0.3, "cadre").setOrigin(0).setDepth(1).setDisplaySize(this.game.renderer.width * 0.85, this.game.renderer.height * 0.5)
        blocInfos.setPosition((this.game.renderer.width * 0.5) - (blocInfos.displayWidth / 2), this.game.renderer.height * 0.3)

        let textQuestion = this.add.text(0, 0, estQuestionDebut ? "" : this.contenu, { fontFamily: 'Metropolis', fontSize: 30, lineSpacing: 15, align: "center", wordWrap: { width: blocInfos.width - 180 } }).setDepth(2)
        textQuestion.setPosition((this.game.renderer.width * 0.5) - (textQuestion.displayWidth / 2), this.game.renderer.height * 0.32)
        this.questionObjet = textQuestion;

        let boutonJouer = this.add.sprite(this.game.renderer.width * 0.86, this.game.renderer.height * 0.85, "bouton_continuer").setDisplaySize(250, 60).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        boutonJouer.setPosition((this.game.renderer.width * 0.5), (this.game.renderer.height * 0.85));
        this.boutonsAnimes.push(boutonJouer);

        boutonJouer.on('pointerover', function (event) {
            boutonJouer.setTint(0xd97777)
        })
        boutonJouer.on('pointerout', function (event) {
            boutonJouer.clearTint()
        })
        boutonJouer.on('pointerdown', function (event) {
            if (!self.unlock) return;

            if (!self.afficherQuestion()) {
                let estQuestionDebut = self.registry.get('questionsDebut') == undefined ? true : self.registry.get('questionsDebut');
                /*                 let sceneData = estQuestionDebut ? {route: "choix", scene: CST.SCENES.CHOIX} : {route: "", scene: CST.SCENES.ACCUEIL}
                                self.scene.start(CST.SCENES.LOAD, sceneData) */
                if (estQuestionDebut) {
                    self.scene.start(CST.SCENES.LOAD, { route: "choix", scene: CST.SCENES.CHOIX })
                } else {
                    window.location.reload();
                }
            }
        })


    }
    update() {
        if (this.scaleBtn >= 0.2) {
            this.increment = -0.005
        } else if (this.scaleBtn <= 0) {
            this.increment = 0.005
        }
        this.scaleBtn = this.scaleBtn + this.increment

        for (let i = 0; i < this.boutonsAnimes.length; i++) {
            this.boutonsAnimes[i].setScale(1 + this.scaleBtn)
            this.boutonsAnimes[i].setPosition((this.game.renderer.width * 0.5), (this.game.renderer.height * 0.85))
        }
    }

    afficherQuestion() {
        let estQuestionDebut = this.registry.get('questionsDebut') == undefined ? true : this.registry.get('questionsDebut');
        if (!estQuestionDebut && this.indexQuestion == 0) {
            this.textTitre.setText("Pistes de réflexion");
            this.textTitre.setPosition((this.game.renderer.width * 0.5) - (this.textTitre.displayWidth / 2), this.game.renderer.height * 0.1)
            this.backgroundTitre.setPosition(this.textTitre.x + this.textTitre.displayWidth / 2, this.textTitre.y + this.textTitre.displayHeight / 2).setDisplaySize(this.textTitre.displayWidth + 50, this.textTitre.displayHeight + 50)
        }


        let questionObj = this.questions[this.indexQuestion];

        if (questionObj) {
            let question = questionObj.question;

            this.questionObjet.setText(question);

            this.questionObjet.setPosition((this.game.renderer.width * 0.5) - (this.questionObjet.displayWidth / 2), this.game.renderer.height * 0.32)

            this.indexQuestion += 1;
        }
        return questionObj != undefined;
    }
}