import { CST } from "../CST"
export class jeuScene extends Phaser.Scene{
    personnageJSON
    constructor(){
        super({
            key:CST.SCENES.JEU
        })
    }
    init(data){
        
        
    }
    create(){

    }
}