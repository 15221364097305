//charge tout les assets avant de passer à la vue menu
import { CST } from "../CST"

export class preloadScene extends Phaser.Scene {
    //sert à stocker les infos de l'api, sera envoyé à la scene choixPersonnage
    listeTypePersonnageJSON

    // Liste à push dans le local storage des saisons actuelles
    listeSaisonsActuelles = ""

    constructor() {
        super({
            key: CST.SCENES.PRELOAD
        })
    }
    //recoit les infos d'autre scenes, charge les plugin
    init() {

    }
    //charge les assets etc
    preload() {
        var self = this

        //charge les assets
        this.load.image("title", "./assets/logocegep.png")

        this.load.image("curseur", "./assets/glassPanel.png")

        this.load.image("panneau", "./assets/Rectangle1.png")

        this.load.image("plateau", "./assets/plateau.png")

        this.load.image("background", "./assets/Fond/fondIconeGrisLogo.svg")
        this.load.svg("cadre_personnage", "./assets/carteRouge.svg")
        this.load.svg("cadre_autonome", "./assets/carteMode1.svg")
        this.load.svg("cadre_animatrice", "./assets/carteMode2.svg")
        this.load.svg("cadre_option", "./assets/cadreOption.svg")
        this.load.svg("cadre_titre", "./assets/cadreTitre.svg")
        this.load.svg("icone_aide", "./assets/iconeAide.svg")
        this.load.svg("calendrier", "./assets/calendrier.svg")

        this.load.svg("bouton_jouer", "./assets/boutonCommencer.svg")
        this.load.svg("bouton_continuer", "./assets/boutonContinuer.svg")
        this.load.svg("bouton_suivant", "./assets/boutonSuivant.svg")
        this.load.svg("bouton_payer", "./assets/boutonPayer.svg")
        this.load.svg("bouton_ne_pas_payer", "./assets/boutonNePasPayer.svg")
        this.load.svg("cadre_bouton", "./assets/cadreBouton.svg")

        this.load.image("avatar1", "./assets/Personnages/avatar1.png")
        this.load.image("avatar2", "./assets/Personnages/avatar2.png")
        this.load.image("avatar3", "./assets/Personnages/avatar3.png")
        this.load.image("avatar4", "./assets/Personnages/avatar4.png")
        this.load.image("avatar5", "./assets/Personnages/avatar5.png")
        this.load.image("avatar6", "./assets/Personnages/avatar6.png")
        this.load.image("avatar7", "./assets/Personnages/avatar3.png")
        this.load.image("avatar8", "./assets/Personnages/avatar8.png")
        this.load.image("avatar9", "./assets/Personnages/avatar9.png")
        this.load.image("avatar10", "./assets/Personnages/avatar10.png")
        this.load.image("avatar11", "./assets/Personnages/avatar11.png")
        this.load.image("avatar12", "./assets/Personnages/avatar12.png")
        this.load.image("avatar13", "./assets/Personnages/avatar13.png")
        this.load.image("avatar14", "./assets/Personnages/avatar14.png")
        this.load.image("avatar15", "./assets/Personnages/avatar15.png")
        this.load.image("avatar16", "./assets/Personnages/avatar16.png")
        this.load.image("avatar17", "./assets/Personnages/avatar17.png")
        this.load.image("avatar18", "./assets/Personnages/avatar18.png")
        this.load.image("avatar19", "./assets/Personnages/avatar19.png")
        this.load.image("avatar20", "./assets/Personnages/avatar20.png")
        this.load.image("avatar21", "./assets/Personnages/avatar21.png")
        this.load.image("avatar22", "./assets/Personnages/avatar22.png")
        this.load.image("avatar23", "./assets/Personnages/avatar23.png")
        this.load.image("avatar24", "./assets/Personnages/avatar24.png")
        this.load.image("avatar25", "./assets/Personnages/avatar25.png")
        this.load.image("avatar26", "./assets/Personnages/avatar26.png")
        this.load.image("avatar27", "./assets/Personnages/avatar27.png")
        this.load.image("avatar28", "./assets/Personnages/avatar28.png")
        this.load.image("avatar29", "./assets/Personnages/avatar29.png")
        this.load.image("avatar30", "./assets/Personnages/avatar30.png")
        this.load.image("avatar31", "./assets/Personnages/avatar31.png")
        this.load.image("avatar32", "./assets/Personnages/avatar32.png")
        this.load.image("avatar33", "./assets/Personnages/avatar33.png")
        this.load.image("avatar34", "./assets/Personnages/avatar34.png")
        this.load.image("avatar35", "./assets/Personnages/avatar35.png")
        this.load.image("avatar36", "./assets/Personnages/avatar36.png")
        this.load.image("avatar37", "./assets/Personnages/avatar37.png")
        this.load.image("avatar38", "./assets/Personnages/avatar38.png")
        this.load.image("avatar39", "./assets/Personnages/avatar39.png")
        this.load.image("avatar40", "./assets/Personnages/avatar40.png")
        this.load.image("avatar41", "./assets/Personnages/avatar41.png")
        this.load.image("avatar42", "./assets/Personnages/avatar42.png")
        this.load.image("avatar43", "./assets/Personnages/avatar43.png")
        this.load.image("avatar44", "./assets/Personnages/avatar44.png")
        this.load.image("avatar45", "./assets/Personnages/avatar45.png")
        this.load.image("avatar46", "./assets/Personnages/avatar46.png")
        this.load.image("avatar47", "./assets/Personnages/avatar47.png")
        this.load.image("avatar48", "./assets/Personnages/avatar48.png")
        this.load.image("avatar49", "./assets/Personnages/avatar49.png")
        this.load.image("avatar50", "./assets/Personnages/avatar50.png")

        this.load.image("cadre", "./assets/cadre.svg")
        this.load.image("cadre_blanc", "./assets/cadreBlanc.svg")
        this.load.image("cadreChoix", "./assets/cadreLoisir.svg")

        var progressBar = this.add.graphics();
        var progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(this.game.renderer.width / 2 - 160, this.game.renderer.height / 2 - 25, 320, 50);

        this.load.on('progress', function (value) {
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(self.game.renderer.width / 2 - 160 + 10, self.game.renderer.height / 2 - 25 + 10, 300 * value, 30);
        });
                    
        this.load.on('complete', function () {
            progressBar.destroy();
            progressBox.destroy();
        });

        this.load.rexAwait((successCallback, failureCallback) => {
            fetch('https://gestion.avoscomptes.org/api/saisons/actuelles', {
                method: 'GET',
            }).then((response) => {
                return response.json()
            }).then((data)=>{
                self.listeSaisonsActuelles +=  "1,"
                for (var i = 0; i < data.length; i++) {
                    self.listeSaisonsActuelles += data[i].id + ","
                };
                
                self.registry.set('saisons', self.listeSaisonsActuelles.slice(0, -1))
                successCallback();
            });
        });
    }

    //METHODE create() EST OBLIGATOIRE DANS TOUTES LES SCENES
    //crée l'objet game
    create() {
        //this.scene.start(CST.SCENES.ACCUEIL)
        this.scene.start(CST.SCENES.LOAD, {route: "textes", scene: CST.SCENES.ACCUEIL})
        //pour lancer une scene indépendamment :
        //this.scene.launch

        //pour donner une scene au main dynamiquement  :
        // this.scene.add(CST.SCENES.MENU,menuScene,false)
    }
}