import { CST } from "../CST"
export class accueilScene extends Phaser.Scene{
    titre
    description
    boutonsAnimes = []
    scaleBtn = 0
    increment = 0.1
    constructor(){
        super({
            key:CST.SCENES.ACCUEIL
        })
    }
    init(data){
        let self = this;
        this.load.plugin('rexmodalplugin', './rexmodalplugin.min.js');

        this.load.scenePlugin({
            key: 'rexuiplugin',
            url: './rexuiplugin.min.js',
            sceneKey: 'rexUI'
        });
        for(let i = 0; i < data.length; i++){
            if(data[i].nom_gestion == "titre_jeu"){
                self.titre = data[i].texte;
            }else if(data[i].nom_gestion == "description_jeu"){
                self.description = data[i].texte;
            }else if(data[i].nom_gestion=="dette"){ 
                self.registry.set("dette",data[i].texte)
            }
        }
    }
    create(){
        self = this
        
        //charge l'image de fond
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setInteractive()
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)
            .on('pointerup', function () {
                //
            })

        let textTitre = this.add.text(0, 0, self.titre, { fontFamily: 'Raleway-ExtraBold', fontSize: 60, color: '#8C2727' }).setDepth(2)
            textTitre.setPosition((this.game.renderer.width * 0.5) - (textTitre.displayWidth / 2), this.game.renderer.height * 0.1)
        let backgroundTitre = this.add.sprite(textTitre.x + textTitre.displayWidth / 2, textTitre.y + textTitre.displayHeight / 2, "cadre_titre").setDepth(1).setDisplaySize(textTitre.displayWidth + 50, textTitre.displayHeight + 50, 0xD4D4D4)
        
        //ajoute une boite ou mettre les infos du personnage selectionné
        //TODO : taille dynamiques
        //n'utilise pas de parametre, affichage non dynamique pour le moment
        let blocInfos = this.add.sprite(this.game.renderer.width * 0.08, this.game.renderer.height * 0.3, "cadre").setOrigin(0).setDepth(1).setDisplaySize(this.game.renderer.width * 0.85, this.game.renderer.height * 0.42)
            blocInfos.setPosition((this.game.renderer.width * 0.5) - (blocInfos.displayWidth / 2), this.game.renderer.height * 0.4)

        //n'utilise pas de parametre, affichage non dynamique pour le moment
        let textInfos = this.add.text(0, 0, self.description, { fontFamily: 'Metropolis', fontSize: 25, lineSpacing: 15, align: "center", wordWrap: {width: blocInfos.width - 180} }).setDepth(2)
            textInfos.setPosition((this.game.renderer.width * 0.5) - (textInfos.displayWidth / 2), this.game.renderer.height * 0.42)

        let boutonJouer = this.add.sprite(this.game.renderer.width *0.415, this.game.renderer.height * 0.9, "bouton_jouer").setOrigin(0).setDepth(1).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer'})
        this.boutonsAnimes.push(boutonJouer)
    
        boutonJouer.on('pointerover', function (event) {
            boutonJouer.setTint(0xd97777)
        })
        boutonJouer.on('pointerout', function (event) {
            boutonJouer.clearTint()
        })
        boutonJouer.on('pointerdown', function (event) {
            self.scene.start(CST.SCENES.CHOIXMODEJEU, {route:"",scene: CST.SCENES.CHOIXTYPEPERSONNAGE })
        })
    }
    update() {
        if (this.scaleBtn >= 0.2) {
            this.increment = -0.005
        } else if (this.scaleBtn <= 0) {
            this.increment = 0.005
        }
        this.scaleBtn = this.scaleBtn + this.increment

        for (let i = 0; i < this.boutonsAnimes.length; i++) {
            this.boutonsAnimes[i].setScale(1 + this.scaleBtn)
            this.boutonsAnimes[i].setPosition((this.game.renderer.width * 0.5) - (this.boutonsAnimes[i].displayWidth / 2), this.game.renderer.height * 0.9)
        }
    }
}



//pour créer la fenetre modale
var CreateDialog = function (scene, nom) {
    var dialog = scene.rexUI.add.dialog({
        background: scene.rexUI.add.roundRectangle(0, 0, 400, 400, 20, 0x657793),

        title: scene.rexUI.add.label({
            background: scene.rexUI.add.roundRectangle(0, 0, 400, 40, 20, 0x233e66),
            text: scene.add.text(0, 0, 'Confirmation', {
                fontSize: '24px'
            }),
            space: {
                left: 15,
                right: 15,
                top: 10,
                bottom: 10
            }
        }),

        content: scene.add.text(0, 0, "Jouer avec le type " + nom + " ?", {
            fontSize: '24px'
        }),

        actions: [
            createLabel(scene, 'Oui'),
            createLabel(scene, 'Non')
        ],

        space: {
            title: 25,
            content: 25,
            action: 15,

            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },

        align: {
            actions: 'right', // 'center'|'left'|'right'
        },

        expand: {
            content: false,  // Content is a pure text object
        }
    })
        .layout().setDepth(3);

    dialog
        .on('button.click', function (button, groupName, index, pointer, event) {
            dialog.emit('modal.requestClose', { index: index, text: button.text });
        })
        .on('button.over', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle(1, 0xffffff);
        })
        .on('button.out', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle();
        });

    return dialog;
}


//pour créer un choix dans la fenetre modale 
var createLabel = function (scene, text) {
    return scene.rexUI.add.label({
        //width: 100,
        //height: 40,

        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, 0xa2adbe),

        text: scene.add.text(0, 0, text, {
            fontSize: '24px'
        }),

        space: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
        }
    });
}