import { CST } from "../CST"
/* import FloatingNumbersPlugin from "../components/FloatingNumbersPlugin"; */
export class calendrierScene extends Phaser.Scene {
    //variables globales
    listeEvenementsJSON
    optionJSON
    animationJSON
    listeJoursApercu = []
    textJour
    //pas besoin de le declarer ici ? Quel est cette sorcellerie ?
    /*    containerEvenement */
    // pour afficher les differents types de saisies
    inputEpargne
    inputCredit
    inputArgent

    iconeAide
    textExplication
    listeConsequences = [-1]
    //permet d'enregistrer les event à ne pas rejouer
    listeEvenementAleatoireJoue = [-1]


    texteRevenusDepense = ""
    detteJSON
    //c'est la liste des boutons et boite saisies générées à chaques event, on les stock pour les detruire plus facilement à chaque nouvel evenement
    listeEntrees = []

    listeMoneyObj = []

    //permet de savoir quel jour on est
    numJour = 1
    //permet de savoir quel evenement afficher dans le jour, se reinitialise tout les jours
    numEvenementGlobal = 0
    // se reinitialise à chaque nouvel evenement
    depense = 0
    gain = 0

    dettePassee = false
    //utilisé quand on charge l'evenement
    idEvent = -1

    argent = 0
    epargne = 0
    credit = 0

    boutonsAnimes = []
    scaleBtn = 0
    increment = 0.1


    constructor() {
        super({
            key: CST.SCENES.CALENDRIER
        })
    }
    init() {
        //affiche le message recu depuis la scene choixPersonnage
        this.personnageJSON = this.registry.get('personnage')
        this.optionJSON = this.registry.get("options")
        this.animationJSON = this.registry.get("animation")
        this.detteJSON = this.registry.get("dette")

        this.load.plugin('rextexteditplugin', 'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rextexteditplugin.min.js', true)

        self = this
    }
    preload() {

    }
    create() {
        this.credit = parseFloat(this.personnageJSON.credit_base)
        this.epargne = parseFloat(this.personnageJSON.epargne_base)
        //charge l'image de fond
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setInteractive()
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)
        //affiche le numero du jour
        this.textJour = this.add.text(0, 0, "Jour " + this.numJour, { fontFamily: 'Raleway-ExtraBold', fontSize: 90, color: '#8C2727' })
        this.textJour.setPosition(this.game.renderer.width * 0.5 - this.textJour.displayWidth / 2, this.game.renderer.height * 0.025).setDepth(2);
        let backgroundTitre = this.add.sprite(this.textJour.x + this.textJour.displayWidth / 2, this.textJour.y + this.textJour.displayHeight / 2, "cadre_titre").setDepth(1).setDisplaySize(this.textJour.displayWidth + 50, this.textJour.displayHeight + 50, 0xD4D4D4)


        this.containerCalendrier = this.add.container(this.game.renderer.width - 200 - 15, 100 + 15).setSize(400, 200)
        let cadreCalendrier = this.add.sprite(0, 0, "cadre").setDisplaySize(this.containerCalendrier.width, this.containerCalendrier.height)
        this.containerCalendrier.add(cadreCalendrier)

        let hauteurIndex = -1;
        let largeurIndex = 0;
        for (let i = 1; i <= 28; i++) {
            if ((i - 1) % 7 == 0) {
                hauteurIndex++;
                largeurIndex = 0;
            }

            let rectangleTMP = this.add.sprite(largeurIndex * (30 + ((400 - 40) / (7 * 2))) - 200 + 15 + 20, hauteurIndex * (30 + ((200 - 20) / (7 * 2))) - 100 + 15 + 20, "calendrier").setDepth(2).setDisplaySize(30, 30)
            rectangleTMP.setTint(0xAEAEAE);
            largeurIndex++;


            this.containerCalendrier.add(rectangleTMP)
            this.listeJoursApercu.push(rectangleTMP)
        }
        this.listeJoursApercu[this.numJour - 1].setTint(0xFFFFFF)

        self = this


        //crée le container affichant l'evenement
        let textTitre = this.add.text(0, 0, "", { align: 'center', fontFamily: 'Raleway-ExtraBold', fontSize: 50, color: '#FFFFFF', wordWrap: { width: this.game.renderer.width / 2 - 30 } }).setOrigin(0, 0)
        this.containerEvenement = this.add.container(0, 0).setSize(this.game.renderer.width / 2, this.game.renderer.height / 2.15)
        let cadre = this.add.sprite(0, 0, "cadre").setDisplaySize(this.containerEvenement.width, this.containerEvenement.height)
        let descriptionEvenement = this.add.text(-cadre.displayWidth / 2 + 15, -cadre.displayHeight / 2 + 15, "", { fontFamily: 'Metropolis', fontSize: 30, wordWrap: { width: 500 } }).setDepth(2)
        this.containerEvenement.add(cadre)
        this.containerEvenement.add(descriptionEvenement)
        this.containerEvenement.add(textTitre)

        this.containerEvenement.setPosition(this.game.renderer.width / 2, this.game.renderer.height * 0.55)

        //Créer le container pour afficher le personnage
        //place les cases en fonction des parametres plus haut

        let largeureCase = 0.1
        let hauteurCase = 0.18
        let container
        container = self.add.container(15, 15)
        //ajoute le cadre
        let cadrePersonnage = self.add.sprite(0, 0, "cadre_personnage").setDisplaySize(self.game.renderer.width * largeureCase * 1.6, self.game.renderer.height * hauteurCase * 1.6).setOrigin(0, 0)
        container.add(cadrePersonnage)
        //container.setSize(cadrePersonnage.width,cadrePersonnage.height)

        //ajoute l'image
        let image = self.add.sprite(0, 0, this.personnageJSON.avatar).setDepth(3).setDisplaySize(self.game.renderer.width * largeureCase, self.game.renderer.height * hauteurCase).setOrigin(0, 0)
        image.setPosition(cadrePersonnage.displayWidth / 2 - image.displayWidth / 2, 15)
        container.add(image)
        //ajoute le texte
        let textPersonnage = self.add.text(0, 0, this.personnageJSON.nom, { fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif', fontSize: 30, align: "center", color: '#8C2727', wordWrap: { width: cadrePersonnage.width * 0.75 } }).setOrigin(0, 0)
        textPersonnage.setPosition(cadrePersonnage.displayWidth / 2 - textPersonnage.displayWidth / 2, image.y + image.displayHeight + 10);
        container.add(textPersonnage);

        //container pour afficher les montants
        let containerMontant = self.add.container(0, 0)

        //crée le container affichant l'argent actuel
        this.containerArgent = this.add.container(0, 0)
        let texteArgentt = this.add.text(0, 0, "Argent : " + this.argent + " $", { fontFamily: 'Metropolis', fontSize: 20 }).setOrigin(0, 0)
        this.containerArgent.setSize(texteArgentt.displayWidth + 50, texteArgentt.displayHeight + 30)
        //this.containerArgent.setPosition(15 + cadrePersonnage.displayWidth + this.containerArgent.displayWidth / 2 + 15, 30);
        let cadreArgent = this.add.sprite(0, 0, "cadre_blanc").setDisplaySize(215, this.containerArgent.height).setOrigin(0.5, 0.5)
        cadreArgent.setTint(0x256623);
        texteArgentt.setPosition(-texteArgentt.displayWidth / 2, -texteArgentt.displayHeight / 2)
        this.containerArgent.add(cadreArgent)
        this.containerArgent.add(texteArgentt)

        //crée le container affichant le crédit actuel
        this.containerCredit = this.add.container(0, this.containerArgent.y + this.containerArgent.displayHeight + 15)
        let texteCredit = this.add.text(0, 0, "Crédit : " + this.credit + " $", { fontFamily: 'Metropolis', fontSize: 20 }).setOrigin(0, 0)
        this.containerCredit.setSize(texteCredit.displayWidth + 50, texteCredit.displayHeight + 30)
        //this.containerCredit.setPosition(15 + cadrePersonnage.displayWidth + this.containerCredit.displayWidth / 2 + 15, 30);
        let cadreCredit = this.add.sprite(0, 0, "cadre_blanc").setDisplaySize(215, this.containerCredit.height)
        cadreCredit.setTint(0x256623);
        texteCredit.setPosition(-texteCredit.displayWidth / 2, -texteCredit.displayHeight / 2)
        this.containerCredit.add(cadreCredit)
        this.containerCredit.add(texteCredit)

        //crée le container affichant l'épargne actuel
        this.containerEpargne = this.add.container(0, this.containerCredit.y + this.containerCredit.displayHeight + 15)
        let texteEpargne = this.add.text(0, 0, "Épargne : " + this.epargne + " $", { fontFamily: 'Metropolis', fontSize: 20 }).setOrigin(0, 0)
        this.containerEpargne.setSize(texteEpargne.displayWidth + 50, texteEpargne.displayHeight + 30)
        //this.containerEpargne.setPosition(15 + cadrePersonnage.displayWidth + this.containerEpargne.displayWidth / 2 + 15, 30);
        let cadreEpargne = this.add.sprite(0, 0, "cadre_blanc").setDisplaySize(215, this.containerEpargne.height)
        cadreEpargne.setTint(0x256623);
        texteEpargne.setPosition(-texteEpargne.displayWidth / 2, -texteEpargne.displayHeight / 2)
        this.containerEpargne.add(cadreEpargne)
        this.containerEpargne.add(texteEpargne)

        containerMontant.add(this.containerArgent)
        containerMontant.add(this.containerCredit)
        containerMontant.add(this.containerEpargne)

        containerMontant.setPosition(15 + cadrePersonnage.displayWidth + this.containerArgent.displayWidth / 2 + 30, cadrePersonnage.displayHeight / 2 - ((this.containerEpargne.height * 3) / 2) + 15);

        this.actualiseArgent()
        //repasse les gains et depenses de l'evenement à 0
        this.depense = 0
        this.gain = 0
        this.getEvenement(this.numJour)
    }

    update() {
        for (let i = 0; this.listeMoneyObj.length > i; i++) {
            let moneyObj = this.listeMoneyObj[i];
            moneyObj.update()
            if (moneyObj.destroyed) {
                this.listeMoneyObj.splice(i, 1)
            }
        }

        if (this.scaleBtn >= 0.2) {
            this.increment = -0.005
        } else if (this.scaleBtn <= 0) {
            this.increment = 0.005
        }
        this.scaleBtn = this.scaleBtn + this.increment

        for (let i = 0; i < this.boutonsAnimes.length; i++) {
            this.boutonsAnimes[i].setScale(1 + this.scaleBtn)
            this.boutonsAnimes[i].setPosition(this.game.renderer.width * 0.5, this.game.renderer.height * 0.9)
        }
    }

    getEvenement(numJour) {
        try {
            fetch('https://gestion.avoscomptes.org/api/evenementByJour', {
                method: 'POST',
                /*  mode: 'no-cors', */
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://gestion.avoscomptes.org/'
                },
                body: JSON.stringify({ "numero": numJour, "personnage_id": this.personnageJSON.id, "type_personnage_id": this.personnageJSON.type_personnage_id, "saisons": this.registry.get('saisons'), "consequences": this.listeConsequences.toString() })
            }).then((response) => {
                if (response.status == 200) {
                    return response.json()
                }
                else {
                    return []
                }
            }).then((data) => {
                this.listeEvenementsJSON = data
                this.numEvenementGlobal = 0

                let y = 0
                let goRandom = true
                //verifie si il y a au moins un evenement
                this.listeEvenementsJSON.forEach(function (event) {
                    //place paye debut mois en premier si detecté
                    if (event.titre == "Premier revenu du mois") {
                        if (y > 0) {
                            let eventTmp = self.listeEvenementsJSON[0]
                            self.listeEvenementsJSON[0] = event
                            self.listeEvenementsJSON[y] = eventTmp
                        }
                    }
                    goRandom = false
                    y++
                })
                //si aucun event trouvé, on charge un seul evenement aleatoire
                if (goRandom) {

                    fetch('https://gestion.avoscomptes.org/api/evenementByJourRandom', {
                        method: 'POST',

                        /*  mode: 'no-cors', */
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': 'https://gestion.avoscomptes.org/'
                        },
                        body: JSON.stringify({ "numero": numJour, "personnage_id": this.personnageJSON.id, "type_personnage_id": this.personnageJSON.type_personnage_id, "saisons": this.registry.get('saisons'), "consequences": this.listeConsequences.toString(), "dejaJoues": this.listeEvenementAleatoireJoue.toString() })
                    }).then((response) => {
                        if (response.status == 200) {
                            return response.json()
                        }
                        else {
                            return []
                        }
                    }).then((data) => {
                        this.listeEvenementsJSON = data
                        if (this.listeEvenementsJSON.length > 0) {
                            let tmp = Math.floor(Math.random() * this.listeEvenementsJSON.length)
                            let eventTMP = this.listeEvenementsJSON[tmp]
                            this.listeEvenementsJSON = []
                            this.listeEvenementsJSON[0] = eventTMP
                            this.listeEvenementAleatoireJoue.push(this.listeEvenementsJSON[0].id)
                        }
                        self.chargeEvenement()
                    });
                } else {
                    self.chargeEvenement()
                }

            });

        } catch {
            this.chargeEvenement()
        }
    }

    chargeEvenement() {
        this.texteRevenusDepense = "\n"
        try {
            try {
                this.idEvent = this.listeEvenementsJSON[this.numEvenementGlobal].id
            } catch {
                this.idEvent = -1
            }
            //affiche saisie si besoin
            try {
                //Input de recherche
                if (this.listeEvenementsJSON[this.numEvenementGlobal].saisie_epargne == 1) {

                    this.inputEpargne = new TextInput(this, this.game.renderer.width * 0.5, this.game.renderer.height * 0.8, 250, "Entrer le montant à epargner", (currentInput, value) => {
                        //verification
                        if (value <= this.argent) {
                            currentInput.setValue(value)

                            this.epargne += parseFloat(value)
                            this.actualiseArgent(parseFloat(value))
                            this.listeMoneyObj.push(new MoneyIncome(this, 570, 200, 50, value))
                        }
                    })

                }
                if (this.listeEvenementsJSON[this.numEvenementGlobal].saisie_credit == 1) {
                    this.inputCredit = new TextInput(this, this.game.renderer.width * 0.5, this.game.renderer.height * 0.81, 300, "Entrer le montant à retirer du credit", (currentInput, value) => {
                        //verification
                        if (value <= this.argent) {
                            this.argent -= parseFloat(value)
                            this.credit -= parseFloat(value)

                            if (this.credit < 0) {
                                this.argent += parseFloat(this.credit) * -1
                                this.credit = 0
                            }
                            this.listeMoneyObj.push(new MoneyIncome(this, 570, 200, 50, -value))

                            this.containerArgent.list[1].setText("Argent : " + this.argent + " $")
                            if (this.argent >= 0) {
                                this.containerArgent.list[0].setTint(0x256623)
                            } else {
                                this.containerArgent.list[0].setTint(0x981919)
                            }
                            this.containerCredit.list[1].setText("Crédit : " + this.credit + " $")
                            if (this.credit > 0) {
                                this.containerCredit.list[0].setTint(0x981919)
                            } else {
                                this.containerCredit.list[0].setTint(0x256623)
                            }
                            currentInput.setValue(value)
                        }

                    })
                }
                if (this.listeEvenementsJSON[this.numEvenementGlobal].saisie_depense == 1) {

                    this.inputArgent = new TextInput(this, this.game.renderer.width * 0.5, this.game.renderer.height * 0.8, 250, "Entrer le montant à depenser", (currentInput, value) => {
                        //verification
                        this.actualiseArgent(parseFloat(value))
                        currentInput.setValue(value)
                    })
                }


            } catch {

            }

            //charge gain
            try {
                if (isNan(this.listeEvenementsJSON[this.numEvenementGlobal].gain)) {
                    this.gain = this.listeEvenementsJSON[this.numEvenementGlobal].gain
                } else {
                    this.gain = 0
                }
            } catch {
                this.gain = 0
            }

            //charge la dépense potentielle (de table depensePotentielle)
            let presenceDeDepensePotentielle = false

            self = this
            fetch('https://gestion.avoscomptes.org/api/evenementGetPresenceDepensePotentielle', {
                method: 'POST',

                /*  mode: 'no-cors', */
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://gestion.avoscomptes.org/'
                },
                body: JSON.stringify({ "id": this.idEvent })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                data.forEach(function (depensePotentielle) {
                    if (depensePotentielle.id != null) {
                        presenceDeDepensePotentielle = true
                    }
                })
                //avant de charger les depenses, on verifie si il y a des liens dp avec l'evenement actuel
                if (presenceDeDepensePotentielle) {
                    self = this
                    fetch('https://gestion.avoscomptes.org/api/evenementGetDepensePotentielle', {
                        method: 'POST',
                        /*  mode: 'no-cors', */
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': 'https://gestion.avoscomptes.org/'
                        },
                        body: JSON.stringify({ "id": this.idEvent, "option_id": this.optionJSON })
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        let depenseExiste = false
                        data.forEach(function (depensePotentielle) {
                            depenseExiste = true
                            self.depense += parseFloat(depensePotentielle.montant)
                            self.texteRevenusDepense += "\n- " + depensePotentielle.nom + " : " + depensePotentielle.montant + " $"
                            self.actualiseAffichage()
                        })
                        //si il y a des liens dp avec cet evenement mais aucun lié aux options choisie, on passe à la suite
                        if (!depenseExiste) {
                            self.evenementSuivant()
                        }
                        //delencher la suite du code ici
                    });
                }
            });
            //charge la dépense (de table depense)
            self = this
            fetch('https://gestion.avoscomptes.org/api/evenementGetDepense', {
                method: 'POST',
                /*   mode: 'no-cors', */
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://gestion.avoscomptes.org/'
                },
                body: JSON.stringify({ "id": this.idEvent, "personnage_id": this.personnageJSON.id })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                data.forEach(function (depense) {
                    self.texteRevenusDepense += "\n- " + depense.nom + " : " + depense.montant + " $"
                    self.depense += parseFloat(depense.montant)
                    self.actualiseAffichage()
                })
            });
            //charge le revenu
            fetch('https://gestion.avoscomptes.org/api/evenementGetRevenu', {
                method: 'POST',
                /*  mode: 'no-cors', */
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://gestion.avoscomptes.org/'
                },
                body: JSON.stringify({ "id": this.idEvent, "personnage_id": this.personnageJSON.id })
            }).then((response) => {
                return response.json()
            }).then((data) => {
                data.forEach(function (revenu) {
                    self.texteRevenusDepense += "\n- " + revenu.nom + " : " + revenu.montant + " $"
                    self.gain += parseFloat(revenu.montant)
                })
                //delencher la suite du code ici

                this.afficherBouton(this.numEvenementGlobal)
                this.afficherEvenement(this.numEvenementGlobal)
            });

        } catch {
            this.afficherBouton(this.numEvenementGlobal)
            this.afficherEvenement(this.numEvenementGlobal)
        }
    }

    //le numEvenement est utile quand il y a plusieurs evenements dans une journée
    //this.containerEvenement.list[0] est le sprite (ajouté en premier dans le container)
    //this.containerEvenement.list[1] est le texte (ajouté en deuxième dans le container)
    //this.containerEvenement.list[2] est le titre (ajouté en troisieme dans le container)
    //this.containerEvenement.list[3] est l'icone d'aide (ajouté en quatrième dans le container)
    afficherEvenement() {
        try {/* + "\nDepense : " + this.depense + "\nGain : " */
            this.containerEvenement.list[1].setText(this.listeEvenementsJSON[this.numEvenementGlobal].description + this.texteRevenusDepense)

            this.containerEvenement.list[2].setText(this.listeEvenementsJSON[this.numEvenementGlobal].titre)

            this.containerEvenement.list[1].setPosition(-this.containerEvenement.list[1].displayWidth / 2, -this.containerEvenement.height / 2 + this.containerEvenement.list[2].displayHeight + 30)

            this.containerEvenement.list[2].setPosition(-this.containerEvenement.list[2].displayWidth / 2, -this.containerEvenement.list[0].displayHeight / 2 + 15);

            if (this.animationJSON == 0 && this.listeEvenementsJSON[this.numEvenementGlobal].explication != null) {
                this.containerEvenement.list[2].setPosition(-this.containerEvenement.list[2].displayWidth / 2 - 30, -this.containerEvenement.list[0].displayHeight / 2 + 15);
                this.iconeAide = this.add.sprite(this.containerEvenement.list[2].x + this.containerEvenement.list[2].displayWidth + 50, this.containerEvenement.list[2].y + this.containerEvenement.list[2].displayHeight / 2, "icone_aide").setDisplaySize(60, 60).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })

                this.containerEvenement.add(this.iconeAide)
                this.iconeAide.tint = 0xFFFFFF
                this.iconeAide.on('pointerdown', function (pointer) {
                    // Permet d'accéder à la variable globale optionSelectionne
                    // Verifie si aucune nest deja selectionné
                    var dialogTMP = Explication(this, this.listeEvenementsJSON[this.numEvenementGlobal].explication).setPosition(this.game.renderer.width / 2, this.game.renderer.height / 2)
                    var config = {
                        manualClose: true,
                        duration: {
                            in: 500,
                            out: 500
                        }
                    }
                    this.rexUI.modalPromise(dialogTMP, config)
                        .then(function (result) {
                            // Passe au choix suivant (à faire : condition pour vérifier si dernier choix)
                            if (result.text == "Retour") {

                            } else {
                                //defaut
                            }

                        })

                }, this); //permet de garder le scope sur la scene 
            }

        } catch {
            this.containerEvenement.list[1].setText("C'est repos aujourd'hui ! ")
            this.containerEvenement.list[2].setText("Aucun paiement n’est prévu aujourd’hui")
            this.containerEvenement.list[2].setPosition(-this.containerEvenement.list[2].displayWidth / 2, -this.containerEvenement.list[0].displayHeight / 2 + 15);
            this.containerEvenement.list[1].setPosition(-this.containerEvenement.list[1].displayWidth / 2, -this.containerEvenement.height / 2 + this.containerEvenement.list[2].displayHeight + 30)
        }
    }

    //affiche des boutons de choix en fonction de l'evenement affiché actuellement
    //soit une dépense, soit un gain, soit un skip
    afficherBouton() {
        try {
            //si il y a une depense non obligatoire, on affiche le bouton pour faire le choix de payer+
            if (this.listeEvenementsJSON[this.numEvenementGlobal].depense > 0 && this.listeEvenementsJSON[this.numEvenementGlobal].choix == 1) {
                //ajoute les depenses obligatoires (dabord le depense sur l'evenement, puis les depenses liées)
                let containerBouton = this.add.container(this.game.renderer.width * 0.5, this.game.renderer.height * 0.825)/* .setSize(this.game.renderer.width / 3, this.game.renderer.height / 3) */
                let boutonPayer = this.add.sprite(0, 0, "cadre_bouton").setDisplaySize(150, 66).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
                let texteBouton = this.add.text(-37, -10, "Payer " + this.listeEvenementsJSON[this.numEvenementGlobal].depense + "$", { fontFamily: 'Metropolis', fontSize: 20 })
                containerBouton.add(boutonPayer)
                containerBouton.add(texteBouton)
                this.listeEntrees.push(containerBouton)

                self = this
                boutonPayer.on('pointerdown', function (event) {
                    //ajoute la dépense à la depense totale de l'evenement
                    self.depense += parseFloat(self.listeEvenementsJSON[self.numEvenementGlobal].depense)
                    containerBouton.destroy()
                })
            } else { //si le depense n'est pas un choix, on ajoute automatiquement la depense à la depense totale de l'evenement
                this.depense += parseFloat(this.listeEvenementsJSON[this.numEvenementGlobal].depense)
            }
        } catch {

        }

        //ajoute les boutons oui et non si jamais il y a une consequence
        try {
            //si il y a une depense non obligatoire, on affiche le bouton pour faire le choix de payer
            if (this.listeEvenementsJSON[this.numEvenementGlobal].evenement_consequence == 1) {

                let containerBoutonConsequence = this.add.container(this.game.renderer.width * 0.5, this.game.renderer.height * 0.9)/* .setSize(this.game.renderer.width / 3, this.game.renderer.height / 3) */
                let boutonOui = this.add.sprite(-150, 0, "bouton_payer").setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
                let boutonNon = this.add.sprite(150, 0, "bouton_ne_pas_payer").setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
                containerBoutonConsequence.add(boutonOui)
                containerBoutonConsequence.add(boutonNon)
                this.listeEntrees.push(containerBoutonConsequence)
                self = this
                boutonOui.on('pointerdown', function (event) {
                    containerBoutonConsequence.destroy()
                    self.cleanEntrees()
                    self.evenementSuivant()
                })
                boutonNon.on('pointerdown', function (event) {
                    self.depense = 0
                    self.listeConsequences.push(self.idEvent = self.listeEvenementsJSON[self.numEvenementGlobal].id)
                    containerBoutonConsequence.destroy()
                    self.cleanEntrees()
                    self.evenementSuivant()
                })
            } else {
                //ajoute le bouton pour regler le gain et depense actuel de l'evenement 
                let boutonSkip = this.add.sprite(0, 0, "bouton_suivant").setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' }).setDepth(1)
                boutonSkip.setPosition(this.game.renderer.width * 0.5, this.game.renderer.height * 0.9);
                this.boutonsAnimes.push(boutonSkip)
                this.listeEntrees.push(boutonSkip)

                self = this
                boutonSkip.on('pointerdown', function (event) {
                    self.cleanEntrees()
                    self.evenementSuivant()

                })
            }
        } catch {
            //ajoute le bouton pour regler le gain et depense actuel de l'evenement 
            let boutonSkip = this.add.sprite(0, 0, "bouton_suivant").setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' }).setDepth(1)
            boutonSkip.setPosition(this.game.renderer.width * 0.5, this.game.renderer.height * 0.85);
            this.boutonsAnimes.push(boutonSkip)
            this.listeEntrees.push(boutonSkip)

            self = this
            boutonSkip.on('pointerdown', function (event) {
                self.cleanEntrees()
                self.evenementSuivant()
            })
            //ajoute le bouton pour regler le gain et depense actuel de l'evenement 
            /*             let containerBoutonSkip = this.add.container(0, 0)
                        let textBoutonSuivant = this.add.text(0, 0, "Suivant", { fontFamily: 'Metropolis', fontSize: 20 })
                        let boutonSkip = this.add.sprite(textBoutonSuivant.displayWidth / 2, textBoutonSuivant.displayHeight / 2, "cadre").setDepth(4).setDisplaySize(textBoutonSuivant.displayWidth + 70, textBoutonSuivant.displayHeight + 50).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
                        containerBoutonSkip.add(boutonSkip)
                        containerBoutonSkip.add(textBoutonSuivant)
            
                        containerBoutonSkip.setPosition(this.game.renderer.width * 0.5 - boutonSkip.displayWidth / 2, this.game.renderer.height * 0.85)
            
                        this.listeEntrees.push(containerBoutonSkip)
            
                        self = this
                        boutonSkip.on('pointerdown', function (event) {
                            self.cleanEntrees()
                            self.evenementSuivant()
            
                        }) */
        }



    }
    evenementSuivant() {
        this.actualiseArgent()
        //repasse les gains et depenses de l'evenement à 0
        this.depense = 0
        this.gain = 0
        this.numEvenementGlobal++
        //verifier si il faut passer au jour suivant
        if (this.numEvenementGlobal >= this.listeEvenementsJSON.length) {
            this.numEvenementGlobal = 0
            this.jourSuivant()
        } else {
            this.chargeEvenement()
        }
    }

    actualiseAffichage() {
        this.containerEvenement.list[1].setText(this.listeEvenementsJSON[this.numEvenementGlobal].description + this.texteRevenusDepense)
    }

    actualiseArgent(argentEnMoins = 0) {
        let nouvelleArgent = 0
        if (argentEnMoins == 0) {
            nouvelleArgent = parseFloat(this.gain) - parseFloat(this.depense)
        } else {
            nouvelleArgent = argentEnMoins * -1
        }
        //change argent en fonction de this.gain et this.depense

        this.argent += nouvelleArgent;

        if (nouvelleArgent != 0) {
            this.listeMoneyObj.push(new MoneyIncome(this, 570, 200, 50, nouvelleArgent))
        }


        if (this.argent < 0) {

            if (!this.dettePassee) {
                this.epargne -= (this.argent * -1)
                this.argent = 0
            }

            if (this.epargne < 0) {
                this.credit += (this.epargne * -1)
                this.epargne = 0
                if (this.credit >= parseFloat(this.personnageJSON.limite_credit)) {
                    this.credit = parseFloat(this.personnageJSON.limite_credit)
                    if (!this.dettePassee) {
                        this.dettePassee = true
                        var dialogTMP = Dette(this, this.detteJSON).setPosition(this.game.renderer.width / 2, this.game.renderer.height / 2)
                        var config = {
                            manualClose: true,
                            duration: {
                                in: 500,
                                out: 500
                            }
                        }
                        this.rexUI.modalPromise(dialogTMP, config)
                            .then(function (result) {
                                if (result.text == "Retour") {
                                } else {
                                    //defaut
                                }
                            })
                    }
                }
            }
        }
        this.containerArgent.list[1].setText("Argent : " + this.argent + " $")
        if (this.argent > 0) {
            this.containerArgent.list[0].setTint(0x256623)
        } else {
            this.containerArgent.list[0].setTint(0x981919)
        }
        this.containerEpargne.list[1].setText("Épargne : " + this.epargne + " $")
        if (this.epargne > 0) {
            this.containerEpargne.list[0].setTint(0x256623)
        } else {
            this.containerEpargne.list[0].setTint(0x981919)
        }
        this.containerCredit.list[1].setText("Crédit : " + this.credit + " $")
        if (this.credit > 0) {
            this.containerCredit.list[0].setTint(0x981919)
        } else {
            this.containerCredit.list[0].setTint(0x256623)
        }
    }

    jourSuivant() {
        this.numJour++
        //nettoie les boutons
        this.cleanEntrees()
        //si calendrier fini (numJour > 28) in charge scene de fin
        if (this.numJour > 28) {

            this.registry.set('questionsDebut', false)
            this.scene.start(CST.SCENES.LOAD, {route: "textes", scene: CST.SCENES.QUESTIONS})
        }
        this.listeJoursApercu[this.numJour - 1].setTint(0xFFFFFF)
        this.textJour.setText("Jour " + self.numJour)
        this.textJour.setPosition(this.game.renderer.width * 0.5 - this.textJour.displayWidth / 2, this.game.renderer.height * 0.025);
        this.getEvenement(self.numJour)
        this.idEvent = -1
    }

    cleanEntrees() {
        try {
            this.iconeAide.destroy()
        }
        catch {

        }
        try {
            this.inputEpargne.destroy()
        }
        catch {

        }
        try {
            this.inputCredit.destroy()
        }
        catch {

        }
        try {
            this.inputArgent.destroy()
        }
        catch {

        }

        this.listeEntrees.forEach(function (element) {
            try {
                element.destroy()
            } catch {

            }

        })
        this.listeEntrees = []
    }

}

// Pour créer la fenetre modale d'explication
var Explication = function (scene, explication) {
    var dialog = scene.rexUI.add.dialog({
        background: scene.rexUI.add.roundRectangle(0, 0, 100, 100, 20, 0xDCBEBE),

        title: scene.rexUI.add.label({
            background: scene.rexUI.add.roundRectangle(0, 0, 100, 40, 20, 0x28C2727),
            text: scene.add.text(0, 0, 'Explication', {
                fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
                fontSize: '24px'

            }),
            space: {
                left: 15,
                right: 15,
                top: 10,
                bottom: 10
            }
        }),

        content: scene.add.text(0, 0, explication, {
            fontSize: '24px',
            fontFamily: 'Metropolis',
            wordWrap: { width: 800 },
            fill: 'black'
        }),

        actions: [
            createLabel(scene, 'Retour')
        ],

        space: {
            title: 25,
            content: 25,
            action: 15,

            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },

        align: {
            actions: 'right', // 'center'|'left'|'right'
        },

        expand: {
            content: false,  // Content is a pure text object
        }
    })
        .layout().setDepth(3);

    dialog
        .on('button.click', function (button, groupName, index, pointer, event) {
            dialog.emit('modal.requestClose', { index: index, text: button.text });
        })
        .on('button.over', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle(1, 0xffffff);
        })
        .on('button.out', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle();
        });

    return dialog;
}

// Pour créer la fenetre modale d'explication
var Dette = function (scene, dette) {
    var dialog = scene.rexUI.add.dialog({
        background: scene.rexUI.add.roundRectangle(0, 0, 100, 100, 20, 0xDCBEBE),

        title: scene.rexUI.add.label({
            background: scene.rexUI.add.roundRectangle(0, 0, 100, 40, 20, 0x28C2727),
            text: scene.add.text(0, 0, 'Dette', {
                fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
                fontSize: '24px'

            }),
            space: {
                left: 15,
                right: 15,
                top: 10,
                bottom: 10
            }
        }),

        content: scene.add.text(0, 0, dette, {
            fontSize: '24px',
            fontFamily: 'Metropolis',
            wordWrap: { width: 800 },
            fill: 'black'
        }),

        actions: [
            createLabel(scene, 'Retour')
        ],

        space: {
            title: 25,
            content: 25,
            action: 15,

            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },

        align: {
            actions: 'right', // 'center'|'left'|'right'
        },

        expand: {
            content: false,  // Content is a pure text object
        }
    })
        .layout().setDepth(3);

    dialog
        .on('button.click', function (button, groupName, index, pointer, event) {
            dialog.emit('modal.requestClose', { index: index, text: button.text });
        })
        .on('button.over', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle(1, 0xffffff);
        })
        .on('button.out', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle();
        });

    return dialog;
}

//pour créer un choix dans la fenetre modale 
var createLabel = function (scene, text) {
    return scene.rexUI.add.label({
        // width: 40,
        // height: 40,

        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, 0x28C2727),

        text: scene.add.text(0, 0, text, {
            fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
            fontSize: '24px'
        }),

        space: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
        }
    });
}

class MoneyIncome {
    text
    amtUpdate = 0
    destroyed = false;

    constructor(scene, x, y, range, argent) {
        this.init(scene, x, y, range, argent)
    }

    init(scene, x, y, range, argent) {
        let color = argent >= 0 ? "#256623" : "#981919";
        let beforeText = argent >= 0 ? "+" : "";

        let xStart = (Math.floor(Math.random() * range) + 1) * (Math.floor(Math.random() * 2) == 1 ? 1 : -1);
        let yStart = (Math.floor(Math.random() * range) + 1) * (Math.floor(Math.random() * 2) == 1 ? 1 : -1);

        this.text = scene.add.text(x + xStart, y + yStart, beforeText + argent.toString() + " $", { fontFamily: 'Metropolis', fontSize: 40, color: color })
    }

    update() {
        this.amtUpdate += 1
        if (this.amtUpdate > 75) {
            this.text.destroy()
            this.destroyed = true
        }
        this.text.y -= 2
    }
}

class TextInput {
    value;
    phaserObjects = [];
    constructor(scene, x, y, w, placeholder, callbackClose) {
        this.init(scene, x, y, w, placeholder, callbackClose)
    }

    init(scene, x, y, w, placeholder, callbackClose) {
        const self = this;

        let background = scene.add.sprite(0, 0, "cadre");
        let backgroundInput = scene.add.rectangle(0, 0, 0, 0, 0xFFFFFF);
        let txtInputObj = scene.add.text(x - w / 2, y + 5, placeholder, { fontFamily: 'Metropolis', fontSize: 15, color: "#000000" })
            .setFixedSize(w, 30);

        background
            .setInteractive()
            .on('pointerdown', function () {
                var config = {
                    onOpen: function (textObject) {
                    },
                    onTextChanged: function (textObject, text) {
                        textObject.text = text;
                    },
                    onClose: function (textObject) {
                        try {
                            let newVal = parseFloat(textObject.text.replace(',', '.'))
                            if ((textObject.text == "") || (isNaN(newVal))) {
                                textObject.setText(placeholder);
                            } else {
                                self.value = newVal < 0 ? -newVal : newVal;
                                callbackClose(self, self.value);
                            }
                        } catch {

                        }

                    },
                    selectAll: true,
                }
                if (txtInputObj.text == placeholder) {
                    txtInputObj.text = '';
                    self.value = undefined;
                }
                scene.rexUI.edit(txtInputObj, config);
            }, this);
        background.setPosition(x, y + txtInputObj.displayHeight / 2).setDisplaySize(txtInputObj.displayWidth + 25, txtInputObj.displayHeight + 25)
        backgroundInput.setPosition(x - (5 / 2) - w / 2, y - (5 / 2)).setSize(txtInputObj.displayWidth + 5, txtInputObj.displayHeight + 5)

        this.phaserObjects.push(background);
        this.phaserObjects.push(backgroundInput);
        this.phaserObjects.push(txtInputObj);
    }

    getValue() {
        return this.value;
    }

    setValue(newVal) {
        this.value = newVal;
    }

    destroy() {
        for (let i = 0; i < this.phaserObjects.length; i++) {
            this.phaserObjects[i].destroy();
        }
    }


}