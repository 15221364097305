import { CST } from "../CST"

export class choixTypePersonnageScene extends Phaser.Scene {
    listeTypePersonnageJSON
    //permet de savoir quel type de personnage est selectionné
    typePersonnageSelectionne = -1
    index = 0;
    dialog
    constructor() {
        super({
            key: CST.SCENES.CHOIXTYPEPERSONNAGE
        })
    }
    init(data) {
        //affiche le message recu depuis la scene loadScene
        this.listeTypePersonnageJSON = data
    }
    preload() {
        this.load.plugin('rexmodalplugin', './rexmodalplugin.min.js');

        this.load.scenePlugin({
            key: 'rexuiplugin',
            url: './rexuiplugin.min.js',
            sceneKey: 'rexUI'
        });
    }
    create() {
        var self = this

        //charge l'image de fond
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)

        let backgroundTitre = this.add.sprite(0, 0, "cadre_titre")
        let textTitre = this.add.text(0, 0, "CHOISIR UN PROFIL", { fontFamily: 'Raleway-ExtraBold', align: "center", fontSize: 60, color: '#8C2727' })
        textTitre.setPosition((this.game.renderer.width * 0.5) - (textTitre.displayWidth / 2), this.game.renderer.height * 0.1)
        backgroundTitre.setDisplaySize(textTitre.displayWidth + 50, textTitre.displayHeight + 50, 0xD4D4D4).setPosition(textTitre.x + textTitre.displayWidth / 2, textTitre.y + textTitre.displayHeight / 2)

        let boutonRetour = this.add.sprite(this.game.renderer.width * 0.04, this.game.renderer.height * 0.95, "cadre_personnage").setDisplaySize(100, 50).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let textRetour = this.add.text(boutonRetour.x - 40, boutonRetour.y - 15, "Retour", { fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center" })

        boutonRetour.on('pointerover', function (event) {
            boutonRetour.setTint(0xd97f77)
        })
        boutonRetour.on('pointerout', function (event) {
            boutonRetour.clearTint()
        })
        boutonRetour.on('pointerdown', function (event) {
            self.scene.start(CST.SCENES.LOAD, { route: "", scene: CST.SCENES.CHOIXMODEJEU })
        })


        //Groupe qui contient les types de personnage
        let groupeTypes = this.add.group()

        let boutonPrev = this.add.sprite(this.game.renderer.width * 0.135, this.game.renderer.height * 0.85, "cadre_personnage").setDisplaySize(250, 60).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let textPrev = this.add.text(boutonPrev.x - 105, boutonPrev.y - 15, "Profils précédents", { fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center" })
        boutonPrev.on('pointerover', function (event) {
            boutonPrev.setTint(0xd97f77)
        })
        boutonPrev.on('pointerout', function (event) {
            boutonPrev.clearTint()
        })
        boutonPrev.on('pointerdown', function (event) {
            if (self.index > 0) {
                self.index--;
                afficherTypePersonnage(self.index, self, groupeTypes);
            }
        })


        let boutonNext = this.add.sprite(this.game.renderer.width * 0.86, this.game.renderer.height * 0.85, "cadre_personnage").setDisplaySize(250, 60).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let textNext = this.add.text(boutonNext.x - 88, boutonNext.y - 15, "Profils suivants", { fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center" })
        boutonNext.on('pointerover', function (event) {
            boutonNext.setTint(0xd97f77)
        })
        boutonNext.on('pointerout', function (event) {
            boutonNext.clearTint()
        })
        boutonNext.on('pointerdown', function (event) {
            if (self.index * 10 < self.listeTypePersonnageJSON.length - 11) {
                self.index++;
                afficherTypePersonnage(self.index, self, groupeTypes);
            }
        })


        afficherTypePersonnage(this.index, self, groupeTypes);


    }
    update() {

    }


}

function afficherTypePersonnage(index, self, groupeTypes) {
    groupeTypes.clear(true, true);

    /* this.input.setDefaultCursor('url(assets/input/cursors/blue.cur), pointer'); */

    //parametre de l'affichage des personnages
    let bordureGauche = 0.15
    let bordureDroite = 0.9
    let distanceCaseHorizontale = 0.2
    let distanceCaseVerticale = 0.33
    let largeureCase = 0.1
    let hauteurCase = 0.18

    //il faudrait calculer nbrCasesLarge automatiquement, ce n'est pas un parametre
    let nbrCasesLarge = 5

    let positionCase = 0;
    for (let i = index * 10; i < index * 10 + 10 && i < self.listeTypePersonnageJSON.length; i++) {
        var typePersonnage = self.listeTypePersonnageJSON
        //place les cases en fonction des parametres plus haut
        let container
        container = self.add.container((self.game.renderer.width * bordureGauche + self.game.renderer.width * distanceCaseHorizontale * positionCase) % self.game.renderer.width * bordureDroite, 25 + (self.game.renderer.height * distanceCaseVerticale) * (parseInt(positionCase / nbrCasesLarge) + 1))
        groupeTypes.add(container)

        //ajoute le cadre
        let cadre = self.add.sprite(0, 0, "cadre_personnage").setDisplaySize(self.game.renderer.width * largeureCase * 1.6, self.game.renderer.height * hauteurCase * 1.6)
        container.add(cadre)

        container.setSize(cadre.width, cadre.height)

        //ajoute l'image
        let image = container.add(self.add.sprite(0, -40, typePersonnage[i].avatar).setDepth(3).setDisplaySize(self.game.renderer.width * largeureCase, self.game.renderer.height * hauteurCase))

        //ajoute le texte
        container.add(self.add.text(-cadre.width / 2 - 10, cadre.height / 4.5, typePersonnage[i].nom, { fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif', fontSize: 30, align: "center", color: '#8C2727', wordWrap: { width: cadre.width } }).setFixedSize(cadre.width + 30, cadre.height / 4))

        //
        container.on('pointerover', function (event) {
            cadre.setTint(0xd97f77);
        });
        container.on('pointerout', function (event) {

            cadre.clearTint();
        });

        container.on('pointerdown', function (pointer) {

            //verifie si aucun personnage nest deja selectionné
            if (self.typePersonnageSelectionne == -1) {
                self.typePersonnageSelectionne = i
                var dialogTMP = CreateDialog(self, typePersonnage[i].nom).setPosition(self.game.renderer.width / 2, self.game.renderer.height / 2)
                var config = {
                    manualClose: true,
                    duration: {
                        in: 500,
                        out: 500
                    }
                }
                self.rexUI.modalPromise(dialogTMP, config)
                    .then(function (result) {
                        //réinitialise le type de personnage selectionné
                        if (result.text == "Oui") {
                            self.scene.start(CST.SCENES.LOAD, { "route": "personnages/type/" + self.listeTypePersonnageJSON[self.typePersonnageSelectionne]["id"], "scene": CST.SCENES.CHOIXPERSONNAGE })
                            self.typePersonnageSelectionne = -1
                        } else if (result.text == "Non") {
                            self.typePersonnageSelectionne = -1
                        } else {
                            //defaut
                        }

                    })
            }

        }, self); //permet de garder le scope sur la scene 

        container.setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        positionCase++;
    }
}

//pour créer la fenetre modale
var CreateDialog = function (scene, nom) {
    var dialog = scene.rexUI.add.dialog({
        background: scene.rexUI.add.roundRectangle(0, 0, 400, 400, 20, 0xDCBEBE),

        title: scene.rexUI.add.label({
            background: scene.rexUI.add.roundRectangle(0, 0, 400, 40, 20, 0x8C2727),
            text: scene.add.text(0, 0, 'Confirmation', {
                fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
                fontSize: '24px'
            }),
            space: {
                left: 15,
                right: 15,
                top: 10,
                bottom: 10
            }
        }),

        content: scene.add.text(0, 0, "Jouer avec le type " + nom + " ?", {
            fontSize: '24px',
            fontFamily: 'Metropolis',
            fill: 'black'
        }),

        actions: [
            createLabel(scene, 'Oui'),
            createLabel(scene, 'Non')
        ],

        space: {
            title: 25,
            content: 25,
            action: 15,

            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },

        align: {
            actions: 'right', // 'center'|'left'|'right'
        },

        expand: {
            content: false,  // Content is a pure text object
        }
    })
        .layout().setDepth(3);

    dialog
        .on('button.click', function (button, groupName, index, pointer, event) {
            dialog.emit('modal.requestClose', { index: index, text: button.text });
        })
        .on('button.over', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle(1, 0xffffff);
        })
        .on('button.out', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle();
        });

    return dialog;
}


//pour créer un choix dans la fenetre modale 
var createLabel = function (scene, text) {
    return scene.rexUI.add.label({
        //width: 100,
        //height: 40,

        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, 0x8C2727),

        text: scene.add.text(0, 0, text, {
            fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
            fontSize: '24px'
        }),

        space: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
        }
    });
}