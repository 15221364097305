import { CST } from "../CST"
export class menuScene extends Phaser.Scene{
    constructor(){
        super({
            key:CST.SCENES.MENU
        })
    }
    init(data){
        //affiche le message recu depuis la scene loadScene
        
    }
    create(){
        
    }
}