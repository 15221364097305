import { CST } from "../CST"
export class choixModeJeuScene extends Phaser.Scene{
    personnageJSON
    constructor(){
        super({
            key:CST.SCENES.CHOIXMODEJEU
        })
    }
    init(){
        
        
    }
    create(){
        self = this

        //charge l'image de fond
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)

        let textTitre = this.add.text(0, 0, "CHOIX DU MODE DE JEU", { fontFamily: 'Raleway-ExtraBold', align: "center", fontSize: 60, color: '#8C2727' }).setDepth(2)
            textTitre.setPosition((this.game.renderer.width * 0.5) - (textTitre.displayWidth / 2), this.game.renderer.height * 0.1);
        let backgroundTitre = this.add.sprite(textTitre.x + textTitre.displayWidth / 2, textTitre.y + textTitre.displayHeight / 2, "cadre_titre").setDepth(1).setDisplaySize(textTitre.displayWidth + 50, textTitre.displayHeight + 50, 0xD4D4D4)

        let boutonAutonome = this.add.sprite(this.game.renderer.width * 0.35, this.game.renderer.height*0.5, "cadre_autonome").setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let boutonAnimatrice = this.add.sprite(this.game.renderer.width * 0.65, this.game.renderer.height*0.5, "cadre_animatrice").setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })


        let boutonRetour = this.add.sprite(this.game.renderer.width * 0.04, this.game.renderer.height*0.95, "cadre_personnage").setDisplaySize(100, 50).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let textRetour = this.add.text(boutonRetour.x - 40, boutonRetour.y -15, "Retour", {fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center"})

        boutonRetour.on('pointerover', function (event) {
            boutonRetour.setTint(0xd97777)
        })
        boutonRetour.on('pointerout', function (event) {
            boutonRetour.clearTint()
        })
        boutonRetour.on('pointerdown', function (event) {
            self.scene.start(CST.SCENES.LOAD, {route: "", scene: CST.SCENES.ACCUEIL})
        })



        boutonAutonome.on('pointerover', function (event) {
            boutonAutonome.setTint(0xd97777)
        })
        boutonAutonome.on('pointerout', function (event) {
            boutonAutonome.clearTint()
        })
        boutonAutonome.on('pointerdown', function (event) {
            //TODO : enregistrer le mode
            self.registry.set('animation', '0')
            self.scene.start(CST.SCENES.LOAD, {route: "typepersonnagesjeu", scene: CST.SCENES.CHOIXTYPEPERSONNAGE})
        })



        boutonAnimatrice.on('pointerover', function (event) {
            boutonAnimatrice.setTint(0xd97777)
        })
        boutonAnimatrice.on('pointerout', function (event) {
            boutonAnimatrice.clearTint()
        })
        boutonAnimatrice.on('pointerdown', function (event) {
            //TODO : enregistrer le mode
            self.registry.set('animation', '1')
            self.scene.start(CST.SCENES.LOAD, {route: "typepersonnagesjeu", scene: CST.SCENES.CHOIXTYPEPERSONNAGE})
        })
    }

    update(){

    }
}