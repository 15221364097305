import { CST } from "../CST"
export class optionsScene extends Phaser.Scene {
    numJour
    listeEvenementsJSON
    listeJoursApercu = []
    textTitre
    containerEvenement
    constructor() {
        super({
            key: CST.SCENES.OPTIONS
        })
        this.numJour = 1
    }
    init(data) {
        //affiche le message recu depuis la scene choixPersonnage
        this.personnageJSON = data

    }
    preload() {
        //@@charge les personnages
        this.load.rexAwait((successCallback, failureCallback) => {
            fetch('https://gestion.avoscomptes.org/api/evenements', {
                method: 'GET'
            }).then((response) => {
                return response.json()
            }).then((data) => {
                this.listeEvenementsJSON = data
                successCallback();
            });
        });
    }
    create() {
        //charge l'image de fond
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setInteractive()
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)
            .on('pointerup', function () {
                //
            })

        this.textTitre = this.add.text(this.game.renderer.width * 0.3, this.game.renderer.height * 0.1, "Jour " + this.numJour, { fontFamily: 'Raleway-ExtraBold', fontSize: 50, color: '#233e66' }).setDepth(2)

        let perso = this.add.text(this.game.renderer.width * 0.8, 80, "Nom : " + this.personnageJSON.nom + "\nPresentation : " + this.personnageJSON.texte_presentation, { fontFamily: 'Metropolis', fontSize: 20 })

        var apercu = this.add.graphics();

        apercu.fillStyle(0xf77f00, 1);
        //  32px radius on the corners
        apercu.fillRoundedRect(32, 32, 450 + 30, 200 + 30, 32);
        apercu.generateTexture()

        for (let i = 1; i <= 28; i++) {

            let rectangleTMP = this.add.sprite((450 * (1 / 7) * i) % 450, (200 * (1 / 4)) * (parseInt((i - 1) / 7) + 1), "plateau").setOrigin(apercu.x, apercu.y).setDepth(3).setDisplaySize(30, 30)
            this.listeJoursApercu.push(rectangleTMP)
        }
        this.listeJoursApercu[this.numJour-1].setTint(0x272DA6)

        let boutonSuivant = this.add.sprite(this.game.renderer.width/2, this.game.renderer.height*0.8, "cadre_personnage").setDisplaySize(100, 100).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })

        var numJourTMP= this.numJour
        var listeJoursApercuTMP = this.listeJoursApercu
        self = this
        boutonSuivant.on('pointerdown', function (event) {
            numJourTMP += 1
            listeJoursApercuTMP[numJourTMP-1].setTint(0x272DA6)
            self.textTitre.setText( "Jour " + numJourTMP)
        })

        this.afficherEvenement()

    }


    afficherEvenement(){
        //clean l'ancien evenement

        //affiche evenement avec description et choix
        this.containerEvenement =  this.add.container(this.game.renderer.width /2, this.game.renderer.height /2).setSize(this.game.renderer.width /3,this.game.renderer.height /3)

        //en cours ici TODO
        let cadre = this.add.sprite(0, 0, "cadre_personnage").setDisplaySize(this.containerEvenement.width, this.containerEvenement.height)
        let descriptionEvenement = this.add.text(this.game.renderer.width * 0.8, 80, "Nom : " + this.personnageJSON.nom + "\nPresentation : " + this.personnageJSON.texte_presentation, { fontFamily: 'Metropolis', fontSize: 20 })

        this.containerEvenement.add(cadre)

    }



    getEvenement(numJour) {
        this.numJour = 1
    }
}