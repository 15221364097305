import { CST } from "../CST"

export class choixScene extends Phaser.Scene {
    // Liste rempli au chargement de la page contenant les différents choix
    listeChoixJSON
    // Liste rempli à l'éxecution de la requête sur la page contenant les différentes options
    listeOptionJSON
    // Mode de jeu selectionne auparavant
    animationJSON

    saisonJSON

    sauvegardeJSON

    iconeAide

    //liste des id de toutes les options selectionne
    listeOptionSelectionne = []
    //Liste des boutons d'options
    listeOption = []
    // Définition du titre
    textTitre
    // Definition de la description
    textInfos
    // Definition de l'id du choix
    idChoix
    // Permet de savoir quel option est selectionné
    optionSelectionne = -1
    // Liste rempli des différentes positions pour les options
    listePositionChoix = [0.3, 0.4, 0.5, 0.6, 0.7, 0.8]
    // Initialisation pour la boucle des choix
    numeroChoix = 0;

    dialog
    constructor() {
        super({
            key: CST.SCENES.CHOIX
        })
    };
    init(data) {
        // Récupération du personnage sélectionné dans la page précédente dans la liste et aussi la liste des choix
        this.listeChoixJSON = data
        this.personnageJSON = this.registry.get("personnage")
        this.animationJSON = this.registry.get("animation")
    };
    preload() {
        this.load.plugin('rexmodalplugin', './rexmodalplugin.min.js');

        this.load.scenePlugin({
            key: 'rexuiplugin',
            url: './rexuiplugin.min.js',
            sceneKey: 'rexUI'
        });
    };
    create() {
        // Permet d'accéder au variable globale
        self = this

        // Charge le fond d'écran
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setInteractive()
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)

        // Ajoute le titre du choix et le centre sur la page
        this.backgroundTitre = this.add.sprite(0, 0, "cadre_titre")
        this.textTitre = this.add.text(0, 0, self.listeChoixJSON[self.numeroChoix].titre, { fontFamily: 'Raleway-ExtraBold', fontSize: 60, color: '#8C2727' })
        this.textTitre.setPosition((this.game.renderer.width * 0.5) - (this.textTitre.displayWidth / 2), this.game.renderer.height * 0.1);
        this.backgroundTitre.setPosition(this.textTitre.x + this.textTitre.displayWidth / 2, this.textTitre.y + this.textTitre.displayHeight / 2).setDisplaySize(this.textTitre.displayWidth + 50, this.textTitre.displayHeight + 50, 0xD4D4D4)


        this.iconeAide = this.add.sprite(-100, -100, "icone_aide").setDisplaySize(60, 60).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        this.iconeAide.setTint(0x8C2727)
        // Affiche icone d'aide en mode de jeu sans animateur
        if (this.animationJSON == 0 && this.listeChoixJSON[this.numeroChoix].explication != null) {
            this.iconeAide.setPosition(this.textTitre.x + this.textTitre.displayWidth + 50, this.textTitre.y + this.textTitre.displayHeight / 2)
            this.backgroundTitre.setDisplaySize(this.backgroundTitre.displayWidth + this.iconeAide.displayWidth + 10, this.backgroundTitre.displayHeight, 0xD4D4D4)
            this.backgroundTitre.setPosition(this.textTitre.x + this.iconeAide.displayWidth + this.textTitre.displayWidth / 2 - 25, this.textTitre.y + this.textTitre.displayHeight / 2)
            this.iconeAide.on('pointerdown', function (pointer) {
                // Permet d'accéder à la variable globale optionSelectionne
                self = this
                // Verifie si aucune nest deja selectionné
                var dialogTMP = Explication(this, this.listeChoixJSON[this.numeroChoix].explication).setPosition(this.game.renderer.width / 2, this.game.renderer.height / 2)
                var config = {
                    manualClose: true,
                    duration: {
                        in: 500,
                        out: 500
                    }
                }
                this.rexUI.modalPromise(dialogTMP, config)
                    .then(function (result) {
                        // Passe au choix suivant (à faire : condition pour vérifier si dernier choix)
                        if (result.text == "Retour") {

                        } else {
                            //defaut
                        }

                    })

            }, this); //permet de garder le scope sur la scene 
        }


        // Ajoute la boite pour afficher la description du choix
        let blocInfos = this.add.sprite(this.game.renderer.width * 0.05, this.game.renderer.height * 0.3, "cadre").setOrigin(0).setDisplaySize(this.game.renderer.width * 0.5, this.game.renderer.height * 0.5);

        // Affiche le texte du choix dans la boite
        this.textInfos = this.add.text(blocInfos.x + 40, blocInfos.y + 20, self.listeChoixJSON[self.numeroChoix].description, { fontFamily: 'Metropolis', fontSize: 30, wordWrap: { width: blocInfos.displayWidth - 50 } });

        // Recupère l'id du choix en cours
        this.idChoix = self.listeChoixJSON[self.numeroChoix].id;

        // Execute la requete de l'Api pour récupérer les options du choix actuel
        this.getByIdAndPersonnage(this.idChoix, this.personnageJSON.id)
    }
    update() {
    }

    afficherChoix() {
        // Change le titre de la page avec le nouveau choix à partir du deuxieme et le centre sur la page
        this.textTitre.setText(self.listeChoixJSON[this.numeroChoix].titre)
        this.textTitre.setPosition((this.game.renderer.width * 0.5) - (this.textTitre.displayWidth / 2), this.game.renderer.height * 0.1);

        this.backgroundTitre.setPosition(this.textTitre.x + this.textTitre.displayWidth / 2, this.textTitre.y + this.textTitre.displayHeight / 2).setDisplaySize(this.textTitre.displayWidth + 50, this.textTitre.displayHeight + 50, 0xD4D4D4)

        // Affiche icone d'aide en mode de jeu sans animateur
        if (this.animationJSON == 0 && this.listeChoixJSON[this.numeroChoix].explication != null) {
            this.iconeAide = this.add.sprite(this.textTitre.x + this.textTitre.displayWidth + 50, this.textTitre.y + this.textTitre.displayHeight / 2, "icone_aide").setDisplaySize(60, 60).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
            this.iconeAide.setTint(0x8C2727)
            this.backgroundTitre.setDisplaySize(this.backgroundTitre.displayWidth + this.iconeAide.displayWidth + 10, this.backgroundTitre.displayHeight, 0xD4D4D4)
            this.backgroundTitre.setPosition(this.textTitre.x + this.iconeAide.displayWidth + this.textTitre.displayWidth / 2 - 25, this.textTitre.y + this.textTitre.displayHeight / 2)
            this.iconeAide.on('pointerdown', function (pointer) {
                // Permet d'accéder à la variable globale optionSelectionne
                self = this
                // Verifie si aucune nest deja selectionné
                var dialogTMP = Explication(this, this.listeChoixJSON[this.numeroChoix].explication).setPosition(this.game.renderer.width / 2, this.game.renderer.height / 2)
                var config = {
                    manualClose: true,
                    duration: {
                        in: 500,
                        out: 500
                    }
                }
                this.rexUI.modalPromise(dialogTMP, config)
                    .then(function (result) {
                        // Passe au choix suivant (à faire : condition pour vérifier si dernier choix)
                        if (result.text == "Retour") {

                        } else {
                            //defaut
                        }

                    })

            }, this); //permet de garder le scope sur la scene 
        }

        // Change le texte du choix avec le nouveau choix à partir du deuxieme
        this.textInfos.setText(self.listeChoixJSON[self.numeroChoix].description)

        // Récupère l'id du choix en cours
        this.idChoix = self.listeChoixJSON[self.numeroChoix].id;

        if (this.numeroChoix >= 1) {
            let boutonRetour = this.add.sprite(this.game.renderer.width * 0.04, this.game.renderer.height * 0.95, "cadre_personnage").setDisplaySize(100, 50).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
            let textRetour = this.add.text(boutonRetour.x - 40, boutonRetour.y - 15, "Retour", { fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center" })

            boutonRetour.on('pointerover', function (event) {
                boutonRetour.setTint(0xd97777)
            })
            boutonRetour.on('pointerout', function (event) {
                boutonRetour.clearTint()
            })
            boutonRetour.on('pointerdown', function (event) {
                self.numeroChoix = 0
                self.listeOptionSelectionne = []
                self.scene.start(CST.SCENES.LOAD, { route: "choix", scene: CST.SCENES.CHOIX })
            })
        }

        // Execute la requete de l'Api pour récupérer les options du choix actuel
        this.getByIdAndPersonnage(this.idChoix, this.personnageJSON.id)
    }

    afficherOption() {
        //si aucune option n'est presente, passe au choix suivant automatiquement
        if (this.listeOptionJSON.length == 0) {
            this.optionSelectionne = -1
            // Augmente de 1 pour passer au choix suivant
            this.numeroChoix++
            // Local Storage
            if (this.numeroChoix == this.listeChoixJSON.length) {
                this.registry.set('options', this.listeOptionSelectionne)
                this.scene.start(CST.SCENES.CALENDRIER)
                return
            }
            // Vide la liste des options
            this.viderListe()
            // Passe au choix suivant
            this.afficherChoix()
        } else {
            for (let i = 0; i < this.listeOptionJSON.length; i++) {

                // Place la case à droite du texte en fonction des positions inscrites au début du programme
                let container
                container = this.add.container(this.game.renderer.width * 0.77, this.game.renderer.height * this.listePositionChoix[i])

                // Ajoute le cadre de l'option
                let cadre = this.add.sprite(0, 0, "cadre_option")
                cadre.setDisplaySize(cadre.width * 1.7, cadre.height).setSize(cadre.width * 1.60, cadre.height)
                container.add(cadre)

                // Prend les mesures du cadre
                container.setSize(cadre.width, cadre.height)

                // Afficher le prix à coté du nom de l'option
                let afficherPrix = " : " + this.listeOptionJSON[i].montant_option + "$"
                if (this.listeOptionJSON[i].montant_option == null) {
                    afficherPrix = ""
                }

                // Ajoute le texte de l'option et le dimensionne
                container.add(this.add.text(-cadre.width / 2, -15, this.listeOptionJSON[i].nom_option + afficherPrix, { fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif', fontSize: 30, align: "center", color: '#8C2727', wordWrap: { width: cadre.width * 0.9 } }).setFixedSize(cadre.width, cadre.height / 2))

                // Ajoute les options à une liste
                this.listeOption.push(container)

                container.on('pointerdown', function (pointer) {
                    // Permet d'accéder à la variable globale optionSelectionne
                    self = this
                    // Verifie si aucune nest deja selectionné
                    if (this.optionSelectionne == -1) {
                        this.optionSelectionne = i
                        this.listeOptionSelectionne.push(this.listeOptionJSON[i].id)
                        var dialogTMP = CreateDialog(this, this.listeOptionJSON[i].nom_option).setPosition(this.game.renderer.width / 2, this.game.renderer.height / 2)
                        var config = {
                            manualClose: true,
                            duration: {
                                in: 500,
                                out: 500
                            }
                        }
                        this.rexUI.modalPromise(dialogTMP, config)
                            .then(function (result) {
                                // Passe au choix suivant (à faire : condition pour vérifier si dernier choix)
                                if (result.text == "Oui") {
                                    self.optionSelectionne = -1
                                    // Augmente de 1 pour passer au choix suivant
                                    self.numeroChoix++
                                    // Local Storage
                                    if (self.numeroChoix == self.listeChoixJSON.length) {
                                        self.registry.set('options', self.listeOptionSelectionne)
                                        self.scene.start(CST.SCENES.CALENDRIER)
                                        return
                                    }
                                    // Vide la liste des options
                                    self.viderListe()
                                    // Passe au choix suivant
                                    self.afficherChoix()
                                } else if (result.text == "Non") {
                                    self.optionSelectionne = -1
                                } else {
                                    //defaut
                                }

                            })
                    }

                }, this); //permet de garder le scope sur la scene 

                container.setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
            }
        }

    }

    // Récupere les options en fonctions du choix et du personnage
    getByIdAndPersonnage(idChoix, idPersonnage) {
        fetch('https://gestion.avoscomptes.org/api/choix/' + idChoix + '/' + idPersonnage, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.listeOptionJSON = data
            self.afficherOption()
        });
    }

    // Vide la liste des options 
    viderListe() {
        this.iconeAide.destroy()
        this.listeOption.forEach(function (option) {
            option.destroy()
        })
        this.listeOption = []
    }
}

// Pour créer la fenetre modale
var CreateDialog = function (scene, nom) {
    var dialog = scene.rexUI.add.dialog({
        background: scene.rexUI.add.roundRectangle(0, 0, 100, 100, 20, 0xDCBEBE),

        title: scene.rexUI.add.label({
            background: scene.rexUI.add.roundRectangle(0, 0, 100, 40, 20, 0x28C2727),
            text: scene.add.text(0, 0, 'Confirmation', {
                fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
                fontSize: '24px'
            }),
            space: {
                left: 15,
                right: 15,
                top: 10,
                bottom: 10
            }
        }),

        content: scene.add.text(0, 0, "Jouer avec " + nom + " ?", {
            fontSize: '24px',
            fontFamily: 'Metropolis',
            fill: 'black'
        }),

        actions: [
            createLabel(scene, 'Oui'),
            createLabel(scene, 'Non')
        ],

        space: {
            title: 25,
            content: 25,
            action: 15,

            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },

        align: {
            actions: 'right', // 'center'|'left'|'right'
        },

        expand: {
            content: false,  // Content is a pure text object
        }
    })
        .layout().setDepth(3);

    dialog
        .on('button.click', function (button, groupName, index, pointer, event) {
            dialog.emit('modal.requestClose', { index: index, text: button.text });
        })
        .on('button.over', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle(1, 0xffffff);
        })
        .on('button.out', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle();
        });

    return dialog;
}

// Pour créer la fenetre modale d'explication
var Explication = function (scene, explication) {
    var dialog = scene.rexUI.add.dialog({
        background: scene.rexUI.add.roundRectangle(0, 0, 100, 100, 20, 0xDCBEBE),

        title: scene.rexUI.add.label({
            background: scene.rexUI.add.roundRectangle(0, 0, 100, 40, 20, 0x28C2727),
            text: scene.add.text(0, 0, 'Explication', {
                fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
                fontSize: '24px'

            }),
            space: {
                left: 15,
                right: 15,
                top: 10,
                bottom: 10
            }
        }),

        content: scene.add.text(0, 0, explication, {
            fontSize: '24px',
            fontFamily: 'Metropolis',
            wordWrap: { width: 800 },
            fill: 'black'
        }),

        actions: [
            createLabel(scene, 'Retour')
        ],

        space: {
            title: 25,
            content: 25,
            action: 15,

            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },

        align: {
            actions: 'right', // 'center'|'left'|'right'
        },

        expand: {
            content: false,  // Content is a pure text object
        }
    })
        .layout().setDepth(3);

    dialog
        .on('button.click', function (button, groupName, index, pointer, event) {
            dialog.emit('modal.requestClose', { index: index, text: button.text });
        })
        .on('button.over', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle(1, 0xffffff);
        })
        .on('button.out', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle();
        });

    return dialog;
}


//pour créer un choix dans la fenetre modale 
var createLabel = function (scene, text) {
    return scene.rexUI.add.label({
        // width: 40,
        // height: 40,

        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, 0x28C2727),

        text: scene.add.text(0, 0, text, {
            fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
            fontSize: '24px'
        }),

        space: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
        }
    });
}
