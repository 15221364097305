import { CST } from "../CST"

export class choixPersonnageScene extends Phaser.Scene {
    listePersonnageJSON
    index = 0
    //permet de savoir quel personnage est selectionné
    personnageSelectionne = -1
    dialog
    constructor() {
        super({
            key: CST.SCENES.CHOIXPERSONNAGE
        })
    }
    init(data) {
        //affiche le message recu depuis la scene loadScene
        this.listePersonnageJSON = data["personnage"]
    }
    preload() {
        this.load.plugin('rexmodalplugin', './rexmodalplugin.min.js');

        this.load.scenePlugin({
            key: 'rexuiplugin',
            url: './rexuiplugin.min.js',
            sceneKey: 'rexUI'
        });
    }
    create() {
        self = this

        this.registry.set('questionsDebut', true)

        //charge l'image de fond
        this.add.image(this.game.renderer.width / 2, this.game.renderer.height / 2, 'background')
            .setInteractive()
            .setDisplaySize(this.game.renderer.width, this.game.renderer.height)
            .on('pointerup', function () {
                //
            })

        let backgroundTitre = this.add.sprite(0, 0, "cadre_titre")
        let textTitre = this.add.text(0, 0, "CHOISIR UN PERSONNAGE", { fontFamily: 'Raleway-ExtraBold', fontSize: 60, color: '#8C2727' })
            textTitre.setPosition((this.game.renderer.width * 0.5) - (textTitre.displayWidth / 2), this.game.renderer.height * 0.1);
        backgroundTitre.setPosition(textTitre.x + textTitre.displayWidth / 2, textTitre.y + textTitre.displayHeight / 2).setDisplaySize(textTitre.displayWidth + 50, textTitre.displayHeight + 50, 0xD4D4D4)
        
            //ajoute une boite ou mettre les infos du personnage selectionné
        //TODO : taille dynamiques
        //n'utilise pas de parametre, affichage non dynamique pour le moment
        let blocInfos = this.add.sprite(this.game.renderer.width * 0.08, this.game.renderer.height * 0.65, "cadre").setOrigin(0).setDisplaySize(this.game.renderer.width * 0.85, this.game.renderer.height * 0.33)

        //n'utilise pas de parametre, affichage non dynamique pour le moment
        let textInfos = this.add.text(blocInfos.x + 40, blocInfos.y + 20, "", { fontFamily: 'Metropolis', fontSize: 30, wordWrap: {width: blocInfos.width - 10} }).setDepth(2)

        let boutonRetour = this.add.sprite(this.game.renderer.width * 0.04, this.game.renderer.height*0.95, "cadre_personnage").setDisplaySize(100, 50).setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let textRetour = this.add.text(boutonRetour.x - 40, boutonRetour.y -15, "Retour", {fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center"})

        boutonRetour.on('pointerover', function (event) {
            boutonRetour.setTint(0xd97777 )
        })
        boutonRetour.on('pointerout', function (event) {
            boutonRetour.clearTint()
        })
        boutonRetour.on('pointerdown', function (event) {
            self.scene.start(CST.SCENES.LOAD, {route: "typepersonnagesjeu", scene: CST.SCENES.CHOIXTYPEPERSONNAGE})
        })


        
        //Groupe qui contient les types de personnage
        let groupeTypes = this.add.group()
        
        let boutonPrev = this.add.sprite(this.game.renderer.width *0.135, this.game.renderer.height*0.61, "cadre_personnage").setDisplaySize(350, 60).setInteractive({cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let textPrev = this.add.text(boutonPrev.x - 145, boutonPrev.y -15, "Personnages précédents", {fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center"})
        
        boutonPrev.on('pointerover', function (event) {
            boutonPrev.setTint(0xd97777)
        })
        boutonPrev.on('pointerout', function (event) {
            boutonPrev.clearTint()
        })
        boutonPrev.on('pointerdown', function (event) {
            if(self.index > 0){
                self.index--;
                afficherTypePersonnage(self.index, self, groupeTypes, textInfos);
            }
        })

        
        let boutonNext = this.add.sprite(this.game.renderer.width *0.86, this.game.renderer.height*0.61, "cadre_personnage").setDisplaySize(350, 60).setInteractive({cursor: 'url(assets/input/cursors/pen.cur), pointer' })
        let textNext = this.add.text(boutonNext.x - 145, boutonNext.y -15, "Personnages suivants", {fontFamily: "Metropolis", fontSize: 24, color: "#8C2727", align: "center"})
            textNext.setPosition(boutonNext.x - (textNext.displayWidth / 2), boutonNext.y - 15);

        boutonNext.on('pointerover', function (event) {
            boutonNext.setTint(0xd97777)
        })
        boutonNext.on('pointerout', function (event) {
            boutonNext.clearTint()
        })
        boutonNext.on('pointerdown', function (event) {
            if(self.index * 5 <= self.listePersonnageJSON.length - 6){
                self.index++;
                afficherTypePersonnage(self.index, self, groupeTypes, textInfos);
            }
        })
        

        afficherTypePersonnage(this.index, self, groupeTypes, textInfos);

    }
    update() {

    }


}

function afficherTypePersonnage(index, self, groupeTypes, textInfos){
    groupeTypes.clear(true, true);
    //parametre de l'affichage des personnages
    let bordureGauche = 0.15
    let bordureDroite = 0.9
    let distanceCaseHorizontale = 0.2
    let distanceCaseVerticale = 0.35
    let largeureCase = 0.1
    let hauteurCase = 0.18

    //il faudrait calculer nbrCasesLarge automatiquement, ce n'est pas un parametre
    let nbrCasesLarge = 5


    let positionCase = 0;
    for (let i = index * 5; i < index * 5 + 5 && i < self.listePersonnageJSON.length ; i++) {

        var personnage = self.listePersonnageJSON

        //place les cases en fonction des parametres plus haut
        let container 
        container= self.add.container((self.game.renderer.width * bordureGauche + self.game.renderer.width * distanceCaseHorizontale * positionCase) % self.game.renderer.width * bordureDroite, 50 + (self.game.renderer.height * distanceCaseVerticale) * (parseInt(positionCase / nbrCasesLarge) + 1))
        groupeTypes.add(container)
        //ajoute le cadre
        let cadre = self.add.sprite(0, 0, "cadre_personnage").setDisplaySize(self.game.renderer.width * largeureCase*1.6, self.game.renderer.height * hauteurCase*1.6)
        container.add(cadre)

        container.setSize(cadre.width,cadre.height)

        //ajoute l'image
        let image=container.add(self.add.sprite(0, -40, personnage[i].avatar).setDepth(3).setDisplaySize(self.game.renderer.width * largeureCase, self.game.renderer.height * hauteurCase))

        //ajoute le texte
        container.add(self.add.text(-cadre.width / 2 - 10, cadre.height / 4.5, personnage[i].nom, { fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif', fontSize: 30, align: "center", color: '#8C2727',wordWrap: { width: cadre.width * 0.5 } }).setFixedSize(cadre.width + 30, cadre.height / 4))

        //
        container.on('pointerover', function (event) {
            cadre.setTint(0xd97777);
            textInfos.setText(personnage[i].texte_presentation)
        });
        container.on('pointerout', function (event) {
            cadre.clearTint();
            textInfos.setText("")
        });

        container.on('pointerdown', function (pointer) {
            //verifie si aucun personnage nest deja selectionné
            if (self.personnageSelectionne == -1) {
                self.personnageSelectionne = personnage[i]
                var dialogTMP = CreateDialog(self, personnage[i].mise_en_contexte, personnage[i].nom).setPosition(self.game.renderer.width / 2, self.game.renderer.height / 2)
                var config = {
                    manualClose: true,
                    duration: {
                        in: 500,
                        out: 500
                    }
                }
                self.rexUI.modalPromise(dialogTMP, config)
                    .then(function (result) {
                        //réinitialise le personnage selectionné
                        if (result.text == "Oui") {
                            self.registry.set('personnage', self.personnageSelectionne)
                            self.scene.start(CST.SCENES.QUESTIONS, {route:"",scene: CST.SCENES.QUESTIONS })
                            self.personnageSelectionne = -1
                        } else if (result.text == "Non") {
                            self.personnageSelectionne = -1
                        } else {
                            //defaut
                        }

                    })
            }

        }, self); //permet de garder le scope sur la scene 
        
        container.setInteractive({ cursor: 'url(assets/input/cursors/pen.cur), pointer'})
        positionCase++;
    }
}

//pour créer la fenetre modale
var CreateDialog = function (scene, miseEnContexte, nom) {
    var dialog = scene.rexUI.add.dialog({
        background: scene.rexUI.add.roundRectangle(0, 0, 100, 100, 20, 0xDCBEBE),

        title: scene.rexUI.add.label({
            background: scene.rexUI.add.roundRectangle(0, 0, 100, 40, 20, 0x28C2727),
            text: scene.add.text(0, 0, 'Confirmation', {
                fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
                fontSize: '24px'
            }),
            space: {
                left: 15,
                right: 15,
                top: 10,
                bottom: 10
            }
        }),

        content: scene.add.text(0, 0, miseEnContexte + "\n\nJouer avec " + nom + " ?", {
            fontSize: '24px',
            fontFamily: 'Metropolis',
            wordWrap: { width: 800 },
            fill: 'black'
        }),

        actions: [
            createLabel(scene, 'Oui'),
            createLabel(scene, 'Non')
        ],

        space: {
            title: 25,
            content: 25,
            action: 15,

            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
        },

        align: {
            actions: 'right', // 'center'|'left'|'right'
        },

        expand: {
            content: false,  // Content is a pure text object
        }
    })
        .layout().setDepth(3);

    dialog
        .on('button.click', function (button, groupName, index, pointer, event) {
            dialog.emit('modal.requestClose', { index: index, text: button.text });
        })
        .on('button.over', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle(1, 0xffffff);
        })
        .on('button.out', function (button, groupName, index, pointer, event) {
            button.getElement('background').setStrokeStyle();
        });

    return dialog;
}


//pour créer un choix dans la fenetre modale 
var createLabel = function (scene, text) {
    return scene.rexUI.add.label({
        // width: 40,
        // height: 40,

        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, 0x28C2727),

        text: scene.add.text(0, 0, text, {
            fontFamily: 'Raleway-ExtraBold,Georgia, "Goudy Bookletter 1911", Times, serif',
            fontSize: '24px'
        }),

        space: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
        }
    });
}