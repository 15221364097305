/** @type {import("../typings/phaser")} */
import { preloadScene } from "./scenes/preloadScene"
import { loadScene } from "./scenes/loadScene"
import { accueilScene } from "./scenes/accueilScene"
import { menuScene } from "./scenes/menuScene"
import { choixModeJeuScene } from "./scenes/choixModeJeuScene"
import { choixTypePersonnageScene } from "./scenes/choixTypePersonnageScene"
import { choixPersonnageScene } from "./scenes/choixPersonnageScene"
import { questionsScene } from "./scenes/questionsScene"
import { choixScene } from "./scenes/choixScene"
import { calendrierScene } from "./scenes/calendrierScene"
import { optionsScene } from "./scenes/optionsScene"
import { jeuScene } from "./scenes/jeuScene"
import AwaitLoaderPlugin from 'phaser3-rex-plugins/plugins/awaitloader-plugin.js';
import { ModalBehavoir } from 'phaser3-rex-plugins/plugins/modal.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import TextEditPlugin from 'phaser3-rex-plugins/plugins/textedit-plugin.js';



let game = new Phaser.Game({
    type: Phaser.AUTO,
    width : 1800,
    height : 1080,
    //definis les scenes utilisée, il faut les avoir importées avant !
    scene:[
        preloadScene,loadScene,accueilScene,menuScene,choixModeJeuScene,choixTypePersonnageScene,choixPersonnageScene,questionsScene,choixScene,jeuScene,calendrierScene
    ],
    parent: 'game',
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    dom: {
        createContainer: true
    },
    plugins: {
        scene: [{
            key: 'rexUI',
            plugin: UIPlugin,
            mapping: 'rexUI'
        }
        ],
        global: [
            {
                key: 'rexAwaitLoader',
                plugin: AwaitLoaderPlugin,
                start: true,
            }/* , {
                key: 'rexTextEdit',
                plugin: TextEditPlugin,
                start: true
            } */
        ]
    },
})



